<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    v-if="ad"
    class="py-4"
  >
    <span
      class="d-flex justify-content-center"
      style="position: relative"
    >
      <a
        :href="target"
        target="_blank"
        class="d-block"
      >
        <img
          :src="source"
          class="d-block m-auto max-width-100"
        />
      </a>
      <a
        style="position: absolute; bottom: -1.5em"
        :href="advertisement"
        class="text-grey pr-3"
      >
        <i class="far fa-info-circle text-info mr-1"></i>
        {{ trans('general.advertisement') }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MapAds',
  data() {
    return { ad: null };
  },
  computed: {
    target() {
      return this.ad ? Routing.generate('ad_redirection', { id: this.ad.id }) : null;
    },
    source() {
      return this.ad.image;
    },
    advertisement() {
      return this.spflocale === 'fr'
        ? 'https://pro.sport-finder.com/faites-votre-pub'
        : 'https://pro.sport-finder.com/en/advertise-with-us';
    },
  },
  created() {
    const elem = document.getElementById('map-ad');

    if (elem) {
      const id = elem.getAttribute('data-id');
      const image = elem.getAttribute('data-image');
      this.ad = { id, image };
    }
  },
};
</script>
