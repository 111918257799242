import Vue from 'vue';
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

import {
  AlertPlugin,
  BadgePlugin,
  ButtonPlugin,
  CalendarPlugin,
  CollapsePlugin,
  DropdownPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  JumbotronPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SidebarPlugin,
  SpinnerPlugin,
  TablePlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue';

import SfMapApp from '../../components/map/MapApp.vue';
// Homepage
import SfHomeSearch from '../../components/home/HomeSearch.vue';
// Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
import MainNav from '../../components/common/Navbar.vue';
import SfInstallBanner from '../../components/Pwa/InstallBanner.vue';
import SfModal from '../../components/common/Modal.vue';

Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CalendarPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(ProgressPlugin);
Vue.use(SidebarPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(VBTooltipPlugin);

Vue.component('SfMapApp', SfMapApp);
Vue.component('SfHomeSearch', SfHomeSearch);
Vue.component('MainNavbar', MainNav);
Vue.component('SfInstallBanner', SfInstallBanner);
Vue.component('SfModal', SfModal);
// Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
